import { TAccessFeature } from "components/AccessChecker";
import lang, { getEnumSelectValues, getEnumTitle, getLangValue } from "lang";

import { getEnumValue, enumToArrayObject } from "./enumHelper";

/**
 * проверка фич
 * @param accessFeatures фичи, которые должны быть
 * @param userFeatures фичи, которые есть у пользователя
 */
export function checkAccessFeatures(
    accessFeatures: TAccessFeature[],
    userFeatures?: TAccessFeature[]
): boolean {
    return accessFeatures.filter((item) => new Set(userFeatures).has(item)).length > 0;
}
export enum UserRoleFlag {
    None = 0,
    Barista = 1,
    BaristaAssistant = 2,
    ChiefInspector = 4,
    CommonEmployee = 8,
    Cook = 16,
    DutyOperator = 32,
    ExternalEvidenceCollector = 64,
    Franchisee = 128,
    Inspector = 256,
    ManagerAssistant = 512,
    OfficeWorker = 1024,
    PortalAdministrator = 2048,
    Probationer = 4096,
    Producer = 8192,
    ShiftSupervisor = 16384,
    Steward = 32768,
    TerritorialDirector = 65536,
    TradePointManager = 131072,
    TradePointTablet = 262144,
    Marketer = 524288,
    Technologist = 1048576,
    OperatingDirector = 2097152,
    UkLeader = 4194304,
    Academy = 8388608,
    Employees = Barista |
        BaristaAssistant |
        CommonEmployee |
        Cook |
        ManagerAssistant |
        Probationer |
        Producer |
        ShiftSupervisor |
        Steward |
        TradePointTablet,
    EmployeeHistory = Barista |
        BaristaAssistant |
        CommonEmployee |
        Cook |
        ManagerAssistant |
        Probationer |
        Producer |
        ShiftSupervisor |
        Steward |
        TradePointManager |
        TerritorialDirector,
    ControlledEmployees = Barista |
        BaristaAssistant |
        CommonEmployee |
        Cook |
        ManagerAssistant |
        Probationer |
        Producer |
        ShiftSupervisor |
        Steward |
        TradePointTablet,

    NoTradePoints = ChiefInspector |
        DutyOperator |
        Inspector |
        OfficeWorker |
        PortalAdministrator |
        Marketer |
        Technologist |
        UkLeader |
        Academy,

    ManyTradePoints = ExternalEvidenceCollector |
        Franchisee |
        TerritorialDirector |
        OperatingDirector |
        TradePointManager,
    EmployeesWithOutTablet = Barista |
        BaristaAssistant |
        CommonEmployee |
        Cook |
        ManagerAssistant |
        Probationer |
        Producer |
        ShiftSupervisor |
        Steward |
        TradePointManager,
}
export enum UserRolesEnum {
    None = 0,
    Barista = 1,
    BaristaAssistant = 2,
    ChiefInspector = 3,
    CommonEmployee = 4,
    Cook = 5,
    DutyOperator = 6,
    ExternalEvidenceCollector = 7,
    Franchisee = 8,
    Inspector = 9,
    ManagerAssistant = 10,
    OfficeWorker = 11,
    PortalAdministrator = 12,
    Probationer = 13,
    Producer = 14,
    ShiftSupervisor = 15,
    Steward = 16,
    TerritorialDirector = 17,
    TradePointManager = 18,
    TradePointTablet = 19,
    Marketer = 20,
    Technologist = 21,
    OperatingDirector = 22,
    UkLeader = 23,
    Academy = 24,
}

/**
 * делает из userRoleId userFlagId
 * @param roleId роль
 * @returns
 */
export function getUserRoleFlagById(roleId: UserRolesEnum): UserRoleFlag {
    if (!roleId) {
        return 0;
    }
    if (roleId === 1) {
        return 1;
    }
    let flag = 1;
    for (let i = 2; i <= roleId; i++) {
        flag *= 2;
    }
    return flag;
}

export type RoleType = "Employees" | "NoTradePoints" | "ManyTradePoints";

/**
 * получаем тип роли пользователя
 * @param roleId роль пользователя
 * @returns тип роли
 */
export function getUserRoleType(roleId: UserRolesEnum): RoleType {
    const flag = getUserRoleFlagById(roleId);
    if (UserRoleFlag.NoTradePoints & flag) {
        return "NoTradePoints";
    }
    if (UserRoleFlag.ManyTradePoints & flag) {
        return "ManyTradePoints";
    }
    return "Employees";
}
export function userRoleTypeTitle(name: RoleType): string {
    const lpr = lang.pipes.roleTypes as any;
    if (name in lpr && typeof lpr?.[name] === "string") {
        return lpr[name];
    }
    return "";
}

export function getUserRoleList(
    roleTypes: RoleType[] = ["Employees", "ManyTradePoints", "NoTradePoints"]
) {
    const roleList = getEnumSelectValues(UserRolesEnum, "UserRolesEnum")
        .filter((x) => x.id > 0)
        .map((x) => ({ ...x, type: userRoleTypeTitle(getUserRoleType(x.id)) }))
        .sort((a, b) => {
            if (a.type === b.type) {
                return a.title.localeCompare(b.title);
            }
            return a.type.localeCompare(b.type);
        });
    return roleList.filter((x) => roleTypes.indexOf(getUserRoleType(x.id)) > -1);
}

/**
 * проверка роли на вхождение
 * @param roleIds роли которые должны быть
 * @param roleId роль которые есть у пользователя
 */
export const checkUserRole = (roleIds: UserRolesEnum[], roleId?: UserRolesEnum): boolean => {
    return !!roleId && roleIds.includes(roleId);
};

/**
 * проверка роли что админ
 * @param roleId роль которые есть у пользователя
 * @returns true если роль является администратором
 */
export const checkUserIsAdminRole = (roleId?: UserRolesEnum): boolean => {
    return !!roleId && [UserRolesEnum.PortalAdministrator].includes(roleId);
};

/**
 * перевод массива ролей в флаг
 * @param roleIds массив ролей
 * @returns флаг
 */
export function userRoleIdsToUserRoleFlag(roleIds: UserRolesEnum[]): UserRoleFlag {
    if (!!roleIds?.length) {
        let flag = getUserRoleFlagById(roleIds[0]);
        for (let i = 1; i < roleIds.length; i++) {
            flag = flag | getUserRoleFlagById(roleIds[i]);
        }
        return flag;
    }
    return 0;
}

/**
 * перевод флага в массив ролей
 * @param roleFlag флаг
 * @returns массив ролей
 */
export function userRoleFlagToUserRoleIds(roleFlag: number): UserRolesEnum[] {
    const result: UserRolesEnum[] = [];
    const roles = enumToArrayObject(UserRolesEnum);
    for (const role of roles) {
        if (roleFlag & getUserRoleFlagById(role.id)) {
            result.push(role.id);
        }
    }
    return result;
}

/**
 * перевод флага в массив имен ролей
 * @param rolesFlag флаг
 * @returns массив ролей
 */
export const userRolesFlagToUserRolesTitleList = (rolesFlag: UserRoleFlag): string[] => {
    if (!rolesFlag) {
        return [lang.no];
    }
    const roles = userRoleFlagToUserRoleIds(rolesFlag).map((r) => getEnumTitle("UserRole", r));
    return roles;
};

/**
 * проверка роли на вхождение в EmployeeHistory
 * @param roleId роль которые есть у пользователя
 * @returns true если роль входит в EmployeeHistory
 */
export const isEmployeeHistoryRole = (roleId: UserRolesEnum): boolean => {
    return (UserRoleFlag.EmployeeHistory & getUserRoleFlagById(roleId)) > 0;
};
