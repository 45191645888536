import ruComponents from "./ru.components";
import ruPages from "./ru.pages";
import ruPlural from "./ru.plural";
import ruPipes from "./ru.pipes";
import ruTables from "./ru.tables";
import ruApi from "./ruApi";

const ru = {
    api: ruApi,
    components: ruComponents,
    pages: ruPages,
    pipes: ruPipes,
    plural: ruPlural,
    tables: ruTables,
    accessDenied: "Доступ запрещен",
    add: "Добавить",
    apply: "Применить",
    all: "Все",
    back: "Назад",
    cancel: "Отмена",
    category: "Категория",
    close: "Закрыть",
    cntShort: "шт.",
    comment: "Комментарий",
    complete: "Завершить",
    confirm: "Подтвердить",
    createdDate: "Дата создания",
    createdUser: "Создал",
    date: "Дата",
    delete: "Удалить",
    department: "Подразделение",
    description: "Описание",
    download: "Скачать",
    deadline: "Крайний срок",
    digit: "Число",
    edit: "Редактировать",
    email: "E-mail",
    error: "Ошибка",
    example: "Образец",
    employee: "Должность",
    filters: "Фильтры",
    fullName: "ФИО",
    image: "Изображение",
    id: "ID",
    iterate: "Повторять",
    loading: "Загрузка...",
    money: "₽",
    moneyShort: "руб.",
    months: {
        january: "Январь",
        february: "Февраль",
        march: "Март",
        april: "Апрель",
        may: "Май",
        june: "Июнь",
        july: "Июль",
        august: "Август",
        september: "Сентябрь",
        october: "Октябрь",
        november: "Ноябрь",
        december: "Декабрь",
    },
    name: "Наименование",
    never: "Никогда",
    next: "Далее",
    no: "Нет",
    notFound: "Не найдено",
    ok: "OK",
    or: "или",
    order: "Порядок",
    orderNumber: "№%s",
    path: "Путь",
    password: "Пароль",
    photo: "Фото",
    phone: "Номер телефона",
    period: "Период",
    region: "Регион",
    report: "Отчет",
    record: "Запись",
    result: "Результат",
    reset: "Сбросить",
    role: "Роль",
    save: "Сохранить",
    search: "Поиск",
    send: "Отправить",
    selectValue: "Укажите значение",
    sec: "сек.",
    start: "Начало",
    status: "Статус",
    s_of_s: "%s из %s",

    tags: "Теги",
    to: "до",
    type: "Тип",
    tradePoint: "Кофейня",
    tradePoints: "Кофейни",
    title: "Название",
    timeZone: "Часовой пояс",
    unknown: "Неизвестно",
    updatedDate: "Дата последнего изменения",
    url: "Ссылка",
    view: "Просмотр",
    video: "Видео",
    value: "Значение",
    warning: "Внимание",
    yes: "Да",
    you: "Вы",

    weekDays: {
        monday: "Понедельник",
        tuesday: "Вторник",
        wednesday: "Среда",
        thursday: "Четверг",
        friday: "Пятница",
        saturday: "Суббота",
        sunday: "Воскресенье",
    },
};
export default ru;
